

.projects {
  height: 100vh;
  width: 100%;
}


.project-container{
  padding-bottom: 100px;
}

.project-container span{
  font-family: "Eczar", serif;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}

.back-button-container{
  width: 70px;
  height: 80px;
}

#back-button{
  position: absolute;
  top: 20px;
  left: 40px;
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  transition: all 0.5s ease-in;
}

#arrow {
  font-size: 25px;
  text-decoration: none;
  margin-right: 5px;
}

#back-button p{
  font-family: "Eczar", serif;
}

#back-button:hover, #back-button:active {
  margin-left: -10px;
  color:#7f72d9;
}

.project-description {
  display: flex;
  flex-direction: row;
}

.project-description img {
  width: 400px;
  border-radius: 10px;
  margin: 10px;
  transform: all 0.5s ease-in-out;
}
.project-description img:hover {
  box-shadow: 0 0 10px gray;
}

.description {
  margin-left: 30px;
  transition: all 0.5s ease-in-out;
}

.project-description h3{
  font-family: "Eczar", serif;
  line-height: 1.2em;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.5s ease-in-out;
}

.project-description h4{
  font-family: "Cormorant Garamond", serif;
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: -10px;
  transition: all 0.5s ease-in-out;
}

.tags{
  margin-top: 15px;
}

.tags > a {
  margin-right: 8px;
}

#github {
  width: 40px;
  margin-left: 0;
  margin-top: 10px;
}

#github:hover {
  background-color: #c7befe;
}

.h4-github {
  padding-top: 20px;
}

.end {
  padding-bottom: 50px;
}

.info-about-project{
  padding-top: 10px;
  color: black;
}

.info-about-project:hover{
  color:#7f72d9;
  cursor: pointer;
}

/* .notShown {
  display: none;
}

.show {
  animation: slide-right 1s;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  overflow-y: hidden;
  height: 100vh;
  background-color: #edf2f4;
  box-shadow: 0 0 40px gray;
} */

/* .close-btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  padding: 5px 10px;
  right: 20px;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.close-btn:hover {
  background-color: #c7befe;
}

.project-information {
  margin: 0 0 0 5%
}

.project-information h1 {
  font-family: "Eczar", serif;
  font-size: 40px;
}

@keyframes slide-right {
  from {
    margin-right: -100%;
  }

  to {
    margin-right: 0%;
  }
} */


@media(max-width: 650px) {
  .project-description {
    flex-direction: column;
  }

  .project-description img {
    width: 200px;
  }

  .description {
    margin-left: 15px;
  }

  .project-description h3 {
    font-size: 20px;
    padding: 0;
  }

  .project-description h4 {
    font-size: 15px;
  }
}