body {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color: #edf2f4;
}

::selection {
  background-color: #c7befe;
}