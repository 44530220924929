footer {
  font-family: "Cormorant Garamond", serif; 
  font-size: 20px;
  text-align: center;
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
  height: 70px;
  background-color: #EDF2F4

}

footer hr {
  border-left: none;
  font-weight: bold;
  margin: 0 50px 0 50px;
}