

#name {
  font-family: "Eczar", serif;
  font-size: 50px;
  margin-bottom: -10px;
  transition: all 0.5s ease-in-out;
}

#title {
  display: flex;
  justify-content: center;
  height: 25px;
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-size: 25px;
  font-style: normal;
  color: #A2A0A5;
}

.typed-cursor {
  color: #A2A0A5;
}

.links {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  display: flex;
  flex-direction: row;
  width: 120px;
  transition: all 0.5s ease-out;
}

.link:hover, .link a:hover, .link:active, .link a:active{
  width: 100px;
  color: #7F72D9;
}


.link .a {
  text-decoration: none;
  color:black;
  transition: color 0.3s ease-in;
}

.link hr {
  width: 30px;
  height: 2px;
  border-left: none;
  color: black;
  margin: 10px 5px;
}



@media(max-width: 410px) {
  #name {
    font-size: 40px;
  }
}