.contact-container {
  margin-top: -200px;
}

.contact-info {
  margin-left: 30px;
}

.title {
  font-family: "Cormorant Garamond", serif;
}

.contact-info img {
  width: 50px;
  padding: 10px;
  transition: all 0.5s ease-in;
}

.contact-info img:hover, .contact-info img:active {
  box-shadow: 0 0 8px #7f72d9;
  background-color: #cdc6ff;
}